<template>
    <div class="breadcrumb-container">
        <ul class="content-container breadcrumb-list">
            <li class="breadcrumb" v-for="(nav, idx) in this.getNavigationList()" :key="nav.name" @click="goTo(nav)" :class="[{ 'active' : isCurrentRoute(nav) }, { 'subnav' : hasSubNav(nav) }, { 'clickable' : isClickable(nav)}]">
                <label>{{ $t(nav.meta.display) }}<label class="sub-page" v-if="$route.meta.subnav && $route.meta.highlight === nav.name">{{ `\n${$t($route.meta.display)}` }}</label> </label>
                <v-icon name="chevron-right" scale="1" v-if="idx <= getNavigationList().length - 2"/>
            </li>
        </ul>
    </div>
</template>

<script>
import 'vue-awesome/icons/chevron-right'

export default {
    name: 'BreadCrumbMenu',
    methods: {
        getNavigationList: function() {
            var result = []
            const availableRoutes = this.$router.getRoutes()

            availableRoutes.forEach(route => {
                if (route.name === 'personal-data' && this.$root.$data.isexistingcustomer) {
                    return
                }

                if (route.meta && route.meta.menuentry) {
                    result.push(route)
                }
            })

            return result
        },
        isCurrentRoute(nav) {
            // meta.highlight allows child routes to hightlight their parent breadcrumb
            return this.$route.name === nav.name || this.$route.meta.highlight === nav.name
        },
        hasSubNav(nav) {
            return this.$route.meta.highlight === nav.name && this.$route.meta.subnav
        },
        goTo(nav) {
            if (!this.isClickable(nav)) {
                return
            }

            // only navigate to page if it is not the current one
            // or if the current route supports subpage navigation
            if (!this.isCurrentRoute(nav) || this.$route.meta.subnav) {
                this.$router.push({ name: nav.name })
            }
        },
        isClickable(nav) {
            return this.$route.meta.weight > nav.meta.weight
        }
    }
}
</script>

<style scoped>
.sub-page {
    font-weight: normal;
    white-space: pre;
}

ul {
    padding: 0;
}

ul > li {
    position: relative;
}

.breadcrumb-container {
    white-space: normal;
    text-align: center;
    padding-left: 10px;
}

.breadcrumb-container > .breadcrumb-list {
    display: flex;
    list-style-type: none;
}

.breadcrumb-container > .breadcrumb-list > .breadcrumb {
    display: flex;
    user-select: none;
    align-items: center;
    min-height: 32px;
}

.breadcrumb-container > .breadcrumb-list > .breadcrumb.active {
    font-weight: bold;
}

.breadcrumb-container > .breadcrumb-list > .breadcrumb > label {
    font-size: 1.2em;
}

.breadcrumb-container > .breadcrumb-list > .breadcrumb > svg {
    fill: #999;
    margin: 0 10px;
    min-width: 10px;
}

.breadcrumb-container > .breadcrumb-list > .breadcrumb.clickable > label:hover {
    cursor: pointer;
}
</style>